import { graphql, useStaticQuery } from 'gatsby'

export const useSiteQuery = () => {
    const {
        contentfulDigitupGlobalSite
    } = useStaticQuery(graphql`
        query {
            contentfulDigitupGlobalSite(id: {eq: "076a50fd-6e0d-5f79-a074-c2ed02fe2745"}) {
                headerLogo {
                    title
                    gatsbyImageData
                    height
                    width
                    file {
                        url
                    }
                }
                footerLogo {
                    title
                    gatsbyImageData
                    height
                    width
                    file {
                        url
                    }
                }
                tempHeaderItems {
                    __typename
                    ...componentLink
                    ... on ContentfulComponentLinkGroup {
                        title
                        sections {
                          title
                          target{
                            ...linkTarget
                          }
                          slug
                        }
                      }
                }
                headerButton {
                    ...componentLink
                }
                address {
                    raw
                  }
                footerCopyrightText
                privacyTitle
                digitupPrivacyNotice{
                    ...componentLink
                }
                privacyNoticeContent{
                    raw
                }
                searchPageAllTags {
                    ... on ContentfulComponentLink {
                        title
                    }
                }
                imagesTesting {
                    gatsbyImageData
                    height
                    width
                    file {
                        url
                    }
                }
                homePageCustomText {
                    raw
                }
                comingSoonPageComponent {
                    ...component2Media
                }
                comingSoonPageContent {
                    comingSoonPageContent
                }
                contactUsTermsConditions {
                    raw
                }
                contactUsDropdownFields {
                    interestedInOptions
                    countries
                }
                thankYouPageComponent {
                    ...component2Media
                }
                thankYouPageContent {
                    thankYouPageContent
                }
                thankYouPageButton {
                    ...componentLink
                }
                homeCwvImages {
                    gatsbyImageData
                    title
                    height
                    width
                    file {
                        url
                    }
                }
                homeCwvRichtext {
                    raw
                }
                homeCwvButtonLink {
                    ...componentLink
                }
                highlightedJobProfiles {
                    slug
                    contentful_id
                    jobProfileTitle
                    location
                    experienceLevel
                    experienceRequired
                    departmentType
                    createdAt(formatString: "DD/MMM/YYYY")
                    jobPostedDate(formatString: "DD/MMM/YYYY")
                }
                jobOverviewHeroImage{
                    gatsbyImageData
                    title
                }
                jobOverviewMobileImage{
                    gatsbyImageData
                    title
                }
                

                lifeAtDigitupSection4

                founderImage{
                    gatsbyImageData

                }
                ecommerceSystemConsulting{
                    raw
                }
                ecommerceSystemAiMl{
                    raw
                }
                ecommerceSystemLetsDoThisImage{
                    gatsbyImageData
                    title
                }
                ecommerceSystemLetsDoThisButton{
                    ...componentLink
                }
                cxEventImage{
                    gatsbyImageData
                    title
                }

                cxEventMobileImage {
                    gatsbyImageData
                    title
                }
                cxEventCards{
                    ...sectionGrid
                }
                cxEventHeroText{
                    ...componentHeroText
                }

                cxEventText{
                    raw
                }
                areYouInterestedInTheEvent{
                    raw
                }
                cxEventSpeaker {
                    type
                    items {
                        ... on ContentfulImageCard {
                        name
                        title
                        image {
                            gatsbyImageData
                        }
                        date
                        icon {
                            gatsbyImageData
                        }
                        }
                    }
                    }
                brandWord{
                    ...sectionCarousel
                }
                brandWordCarousel2{
                    ...sectionCarousel
                }
                brandWordCarousel3{
                    ...sectionCarousel
                }
                brandWordCarousel4{
                    ...sectionCarousel
                }
                brandWordCarousel5{
                    ...sectionCarousel
                }
                logoBrandWord {
                    title
                    gatsbyImageData
                    height
                    width
                    file {
                        url
                    }
                }
            }
        }
    `)

    return contentfulDigitupGlobalSite
}
