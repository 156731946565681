import React from 'react'
import * as styles from './privacy-notice.module.scss'
import Close from "./../../../static/close-button.svg"
import { useLink, useSiteQuery } from '../../../hooks'
import { RichText } from '../../RichText/RichText'

const PrivacyNotice = ({ setter }) => {

    const { privacyNoticeContent, privacyTitle } = useSiteQuery()
    return (
        <div className={`${styles.container} container container-fs`}>

            <div className={styles.popupContainer}>
                <div className={styles.titleContainer}>
                    <div className={`heading heading-1`}>
                        {privacyTitle}
                    </div>
                    <div className={styles.close} onClick={() => setter(false)}><Close /></div>
                </div>
                <div className={styles.contentContainer}>
                    <RichText content={privacyNoticeContent} />
                </div>
            </div>
        </div>
    )
}

export default PrivacyNotice
