import React, { useEffect, useState } from 'react'
import * as styles from './mobile-header.module.scss'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby-link'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useLink } from '../../../hooks'
import Dropdown from '../../../static/header-arrow-dropdown.svg'

const MobileHeader = ({
    logo,
    items
}) => {
    const scrollTo = (id) => {
        closeBanner()
        if (document.getElementById(`${id}`))
            window.scrollTo({
                top: document.getElementById(`${id}`).offsetTop - 80,
                behavior: "smooth",
            })
    }

    const closeBanner = () => {
        document.getElementById('naviToggle').checked = false;
    }

    const [homeLocation, setHomeLocation] = useState(false)

    const { pathname, hash } = useLocation()
    useEffect(() => {
        if (pathname === '/') setHomeLocation(true)
        else setHomeLocation(false)
    }, [pathname])


    return (
        <div>
            <div className={styles.container}>
                <div className={styles.headerContainer}>
                    {/* <GatsbyImage
                        image={logo.gatsbyImageData}
                        alt={logo.title}
                        className={styles.logo}
                        objectFit="contain"
                        onClick={e => scrollTo('about-us')}
                    /> */}

                    {
                        homeLocation
                            ? <Link to="/about-us" className={styles.headerLink}>
                                <div className={styles.headerLogo}>
                                    {/* <GatsbyImage
                                        objectFit="contain"
                                        image={headerLogo.gatsbyImageData}
                                        alt={headerLogo.title}
                                    /> */}

                                    <img src={logo.file.url} alt={logo.title} height={logo.height}
                                        width={logo.width} className={styles.log} />

                                </div>
                            </Link>
                            : <Link to={`/`} className={styles.headerLink}>
                                <div className={styles.headerLogo}>
                                    {/* <GatsbyImage
                                        objectFit="contain"
                                        image={headerLogo.gatsbyImageData}
                                        alt={headerLogo.title}
                                    /> */}
                                    <img src={logo.file.url} alt={logo.title} height={logo.height}
                                        width={logo.width} className={styles.log} />

                                </div>
                            </Link>
                    }
                    <input
                        type="checkbox"
                        id="naviToggle"
                        aria-label='Navigation'
                        autoComplete='navigation'
                        className={styles.navigation__checkbox}
                        hidden
                    />
                    <label
                        htmlFor="naviToggle"
                        className={styles.navigation__button}
                    >
                        <span className={styles.navigation__icon}>&nbsp;</span>
                    </label>
                </div>
            </div>
            <ul className={styles.navMenu}>
                {
                    items.map((item, index) => {
                        switch (item.__typename) {
                            case "ContentfulComponentLink":
                                return item.slug && item.slug.includes('#') && homeLocation
                                    ? <li key={index} className={styles.headerItem} onClick={e => scrollTo(item.slug.replace('#', ''))}>
                                        <a className={styles.headerItem}>{item.title}</a>
                                    </li>
                                    : <li key={index} className={styles.headerItem} onClick={e => closeBanner()}>
                                        <Link to={`/${useLink(item.target?.slug || item.slug)}`} className={styles.headerItem}>{item.title}</Link>
                                    </li>
                            case "ContentfulComponentLinkGroup":
                                return <li key={index} className={`${styles.headerItem}`}>
                                    <input hidden type="checkbox" name="" className={styles.groupCheckbox} id={`mobile-header-${index}`} />
                                    <label htmlFor={`mobile-header-${index}`} className={`${styles.headerItemsGroupTitle}`}>
                                        <span>{item.title}</span>
                                        <Dropdown className={styles.open} />
                                    </label>
                                    <ul className={styles.headerItemGroup}>
                                        {
                                            item.sections.map(({ title, target, slug }, index) => (
                                                <li key={index} className={styles.headerItem} onClick={e => closeBanner()}>
                                                    <Link to={`/${useLink(target?.slug || slug)}`} className={styles.headerItem}>{title}</Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </li>
                        }
                    })
                }
            </ul>
        </div >
    )
}

export default MobileHeader