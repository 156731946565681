import { Link } from 'gatsby-link'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { useLink, useSiteQuery } from '../../../hooks'
import * as styles from './footer.module.scss'
import { RichText } from './../../RichText/RichText'
import PrivacyNotice from '../PrivacyNotice/PrivacyNotice'
import LinkdIN from '../../../images/in.svg';
import Instagram from '../../../images/ig.svg';

const Footer = () => {

    // useEffect(() => {
    //     const chatScript = document.createElement("script");
    //     chatScript.src = "https://static.elfsight.com/platform/platform.js";
    //     chatScript.async = true;
    //     chatScript.defer = true;
    //     chatScript.innerHTML = `data-use-service-core`;

    //     document.body.appendChild(chatScript);

    // }, [])

    const { headerLogo, footerCopyrightText, address, digitupPrivacyNotice, footerLogo } = useSiteQuery()

    const scrollTo = (id) => {
        if (document.getElementById(`${id}`))
            window.scrollTo({
                top: document.getElementById(`${id}`).offsetTop - 80,
                behavior: "smooth",
            })
    }

    const [openPrivacyNotice, setOpenPrivacyNotice] = useState(false)

    return (
        <footer className={`${styles.mainContainer} `}>
            {/* <div className={`elfsight-app-ad158bbb-0b8d-4e23-aaf0-b0a8d342afd4`} data-elfsight-app-lazy></div> */}

            {
                openPrivacyNotice
                    ? <PrivacyNotice
                        setter={setOpenPrivacyNotice}
                    />
                    : null
            }
            <div className={`${styles.footerContainer} container container-xl`}>
                <div className={styles.container}>
                    <div className={styles.footerLogo}>
                        <Link to={"/"}>
                            <img src={footerLogo.file.url} height={footerLogo.height} width={footerLogo.width} alt={footerLogo.title} className={styles.log}  loading='lazy'/>
                            {/* <img src='../../../images/digitup-footer.png' alt='image' className={styles.log} /> */}
                        </Link>
                        <p className={styles.copyRightText}>{footerCopyrightText}</p>
                        <div className={styles.address}>
                            <RichText content={address} />
                        </div>
                    </div>
                    <div className={styles.navigation}>
                        <Link to={"/core-web-vital-checker"}>
                            CWV Checker
                        </Link>
                        <Link to={"/about-us"}>
                            About Us
                        </Link>
                        <Link to={"/blog"}>
                            Blogs
                        </Link>
                        <Link to={"/careers"}>
                            Careers
                        </Link>
                        <Link to={"/marketing-technology-summit-by-et-brandequity-2024"}>
                            Events
                        </Link>
                    </div>
                </div>

                <div className={styles.containerOne}>
                    <div className={styles.belowText}>
                        <p className={styles.social}>Follow Us</p>
                        <div className={styles.socialIcons}>
                            <a className={styles.linkedin} href="https://www.linkedin.com/company/digitupindia?trk=blended-typeahead" target="_blank" rel="noopener noreferrer">
                                <LinkdIN alt="LinkdIN" />

                            </a>
                            <a className={styles.instagram} href="https://www.instagram.com/digitupindia/" target="_blank" rel="noopener noreferrer">
                                <Instagram alt="Instagram" />


                            </a>
                        </div>
                    </div>
                    <div className={`${styles.privacyNotice} privacyNotice`}>
                        <Link to={`/${useLink(digitupPrivacyNotice.slug)}`}>
                            Privacy Notice
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer