import React from 'react'
import Layout from './src/components/Layout/Layout'
import Scripts from './src/components/Layout/Scripts'
import { ParallaxProvider } from 'react-scroll-parallax';
import { CwvProvider } from './src/context/context';
export const wrapPageElement = ({ element }) => {
    return (
        <>
            <Scripts />
            <CwvProvider>
                <ParallaxProvider>
                    <Layout>
                        {element}
                    </Layout>
                </ParallaxProvider>
            </CwvProvider>
        </>
    )
}