import { useState } from "react"
import { navigate } from 'gatsby'

interface state {
    response: any | null,
    loading: boolean,
    error: any | null
}

export const useSendEmail = () => {
    const [state, setState] = useState<state>({
        response: null,
        loading: false,
        error: null
    })

    const sendEmail = async (email: string) => {
        setState({
            response: null,
            loading: true,
            error: null
        })

        const body = {
            name: email,
            description: email
        }

        try {
            const result = await fetch(`/.netlify/functions/clickup-create-task`, {
                method: 'POST',
                body: JSON.stringify(body)
            })

            const output = await result.json()
            navigate('/thank-you/')

            setState({
                response: output,
                loading: false,
                error: null
            })
        } catch (error) {
            setState({
                response: null,
                loading: false,
                error: error
            })
        }
    }

    return [sendEmail, state]

}