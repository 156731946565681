import React, { useEffect, useState } from 'react'
import * as styles from "./header.module.scss"

import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useLink, useSiteQuery } from '../../../hooks'

import MobileHeader from '../MobileHeader/MobileHeader'
import LinkGroup from '../../LinkGroup/LinkGroup'

const Header = () => {
    const { headerLogo, tempHeaderItems, headerButton } = useSiteQuery()

    const scrollTo = (id) => {
        window.scrollTo({
            top: document.getElementById(`${id}`)?.offsetTop - 80,
            behavior: "smooth",
        })
    }

    const [homeLocation, setHomeLocation] = useState(false)

    const { pathname, hash } = useLocation()
    useEffect(() => {
        setHomeLocation(pathname === '/')
    }, [pathname])

    return (
        <header>
            <div className={`${styles.mainContainer}`}>
                <div className={`container-xl ${styles.headerContainer}`}>
                    <Link to={homeLocation ? "/about-us" : "/"} className={styles.headerLink}>
                        <div className={styles.headerLogo}>
                            <img
                                src={headerLogo.file.url}
                                height={headerLogo.height}
                                width={headerLogo.width}
                                alt={headerLogo.title}
                                className={styles.logo}
                            />
                        </div>
                    </Link>
                    <div className={styles.headerMenuGroup}>
                        {tempHeaderItems.map((item, index) => {
                            switch (item.__typename) {
                                case "ContentfulComponentLink":
                                    return item.slug && item.slug.includes('#') && homeLocation
                                        ? <li key={index} className={styles.headerMenu} onClick={e => scrollTo(item.slug.replace('#', ''))}>
                                            <a href={`#${item.slug.replace('#', '')}`} className={styles.headerLink} title={`Scroll to ${item.title}`}>{item.title}</a>
                                        </li>
                                        : <li key={index} className={styles.headerMenu}>
                                            <Link to={`/${useLink(item.target?.slug || item.slug)}`} className={styles.headerLink} title={`Go to ${item.title}`}>{item.title}</Link>
                                        </li>
                                case "ContentfulComponentLinkGroup":
                                    return <LinkGroup item={item} key={index} homeLocation={homeLocation} scrollTo={scrollTo} />
                            }
                        })}
                    </div>
                    <div>
                        <button type="button" className={`${styles.headerBtn}`}>
                            {
                                headerButton.slug && headerButton.slug.includes('#') && homeLocation
                                    ? <a onClick={e => scrollTo(headerButton.slug.replace('#', ''))} className="static-blue-button" title={`Scroll to ${headerButton.title}`}>{headerButton.title}</a>
                                    : <Link to={`/${useLink(headerButton.target?.slug || headerButton.slug)}`} className="static-blue-button" title={`Go to ${headerButton.title}`}>
                                        {headerButton.title}
                                    </Link>
                            }
                        </button>
                    </div>
                </div>
            </div>
            <MobileHeader
                logo={headerLogo}
                items={tempHeaderItems}
            />
        </header>
    )
}

export default Header
