import React, { useState, useContext } from "react";
import { CwvContext } from '../../context/context'

export interface IsState {
    response?: any,
    loading: Boolean,
    error?: String
}

export interface formData {
    url: string,
    device: string,
    type: string
}

export function usePerformance() {
    const [state, setState] = useState<IsState | null>({
        response: null,
        loading: false,
        error: null
    })

        const {setCwvError, cwvDevice, cwvType, cwvError} = useContext(CwvContext)

    async function performanceTest(data: formData, ) {
        //  console.log('data.url', data)
        //  console.log("cwvDevice", cwvDevice);
        //  console.log('cwvType', cwvType);
         

        setState({ response: null, loading: true, error: null })
        let a
        let resBody = {};
        data.type == 'origin' ?  resBody = {
                    "origin": data.url,
                    "formFactor": data.device
                }
                : resBody = {
                    "url": data.url,
                    "formFactor": data.device
                }
                

        //console.log('res body',resBody)

        try {
            await fetch(`https://chromeuxreport.googleapis.com/v1/records:queryRecord?key=AIzaSyBoKE99cjC_bM_PBXv2n8l44qFolcYrWZo`, {
                method: 'POST',
                body: JSON.stringify(resBody)
                // redirect: 'follow'
            })
                .then(response => response.text())
                .then(result => {
                    const finalResult = JSON.parse(result)
                    console.log('finalResult',finalResult)

                    const webvitalmetric = finalResult?.record;

                    setCwvError(() => finalResult?.error)
                    

                    // const lighthouse = finalResult.lighthouseResult;
                    // const performance = lighthouse.categories.performance.score
                    // const lighthouseOthers = {
                    //     'First Contentful Paint': (lighthouse.audits['first-contentful-paint'].numericValue),
                    //     'Speed Index': (lighthouse.audits['speed-index'].numericValue),
                    //     'Time To Interactive': (lighthouse.audits['interactive'].numericValue),
                    //     'First Meaningful Paint': (lighthouse.audits['first-meaningful-paint'].numericValue),
                    //     'Largest Contentful Paint': (lighthouse.audits['largest-contentful-paint'].numericValue),
                    //     'Total Blocking Time': (lighthouse.audits['total-blocking-time'].numericValue),
                    // };

                    // const lighthouseMetrics = {
                    //     'First Contentful Paint': lighthouse.audits['first-contentful-paint'].displayValue,
                    //     'Speed Index': lighthouse.audits['speed-index'].displayValue,
                    //     'Time To Interactive': lighthouse.audits['interactive'].displayValue,
                    //     'First Meaningful Paint': lighthouse.audits['first-meaningful-paint'].displayValue,
                    //     'Largest Contentful Paint': lighthouse.audits['largest-contentful-paint'].displayValue,
                    //     'Total Blocking Time': lighthouse.audits['total-blocking-time'].displayValue
                    // };


                    // var dateObj = new Date();
                    // var month = dateObj.getUTCMonth() + 1; //months from 1-12
                    // var day = dateObj.getUTCDate();
                    // var year = dateObj.getUTCFullYear();

                    // let newdate = year + "/" + month + "/" + day;
                    // // console.log("qwe", {
                    // //     date: newdate,
                    // //     metrics: lighthouseOthers,
                    // //     displayedValues: lighthouseMetrics
                    // // });

                    setState(
                        {
                            response: {
                                data: {'metric': webvitalmetric?.metrics, 'collectionPeriod': webvitalmetric?.collectionPeriod, 'key': webvitalmetric.key, 'error': finalResult?.error},
                            },
                            loading: false,
                            error: null
                        }
                    )

                    a = {
                        response: {
                            data: {'metric': webvitalmetric?.metrics, 'collectionPeriod': webvitalmetric?.collectionPeriod, 'key': webvitalmetric.key, 'error': finalResult?.error},
                        },
                        loading: false,
                        error: null
                    }
                }).catch(err => console.log(err)
                )
        } catch (error) {
            console.error(error);
        }
        return a
    }

    return [performanceTest, state]
}