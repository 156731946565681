import React from 'react'
import * as styles from "./richtextGrid.module.scss"

import { GatsbyImage } from 'gatsby-plugin-image';

import { RichText } from '../RichText';

import SectionGrid from '../../sections/SectionGrid/SectionGrid';
import RichTextServiceCard from './RichTextServiceCard/RichTextServiceCard';

export const RichTextGrid = ({ data: { title, items, cardHeight } }) => {
    return (
        <div
            className={`${styles.richtextGridSection} ${items.length % 2 === 1 ? styles.oddGrid : ''}`}
        >
            {items.map((item, index) => (
                <li className={`${styles.listItems}`} key={index}>
                    {item.type === "content-same" ? (
                        <RichTextServiceCard data={item} cardHeight={cardHeight} />
                    ) : item.type === "content-separate" ? (
                        <div>
                            {item.thumbnailImage ? (
                                <GatsbyImage
                                    image={item.thumbnailImage.gatsbyImageData}
                                    alt={item.thumbnailImage.title}
                                />
                            ) : null}
                            <div className="heading-4">{item?.title}</div>
                            <p>{item?.date}</p>
                            {item.content ? <RichText content={item.content} /> : null}
                        </div>
                    ) : null}
                </li>
            ))}
        </div>
    )
}
