import React from 'react'
import * as styles from './rich-text-link.module.scss'

import { Link } from 'gatsby'
import { useLink } from '../../../hooks'

const RichTextLink = ({
    slug,
    title
}) => {
    return (
        <Link to={`/${useLink(slug)}`} className={styles.link}>{title}</Link>
    )
}

export default RichTextLink