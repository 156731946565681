import React, { useState } from "react"
import { navigate } from 'gatsby'

export interface IsState {
    response?: any,
    loading: Boolean,
    error?: any
}

export interface formData {
    ticket: {
        subject: string,
        comment: {
            html_body: string,
        },
        requester: {
            name: string,
            email: string
        },
        custom_fields: any
    }
}


export const useContactUs = () => {
    const [state, setState] = useState<IsState | null>({
        response: null,
        loading: false,
        error: null
    })

    const contactUs = async (data: formData) => {
        setState({ response: null, loading: true, error: null })
        try {
            const output = await fetch('/.netlify/functions/zendesk-form', {
                method: 'POST',
                body: JSON.stringify(data)
            })
            const res = await output.json()
            setState({ response: res, loading: false, error: null })
            navigate('/thank-you/')
        } catch (error) {
            setState({
                response: null,
                loading: false,
                error: error
            })
        }
    }

    return [contactUs, state]
}