import React, { createContext, useState } from "react";

export const CwvContext = createContext({})

export const CwvProvider = ({ children }) => {
    const [cardShow, setCardShow] = useState(false)
    const [cwvData, setCwvData] = useState({})
    const [cwvStatus, setCwvStatus] = useState({})
    const [cwvResult, setCwvResult] = useState({})
    const [cwvError, setCwvError] = useState({})
    const [cwvDevice, setCwvDevice] = useState('PHONE')
    const [cwvType, setCwvType] = useState('origin')
    const [url, setUrl] = useState('')

    return (
        <CwvContext.Provider value={{
            cardShow,
            setCardShow,
            cwvData,
            setCwvData,
            cwvStatus,
            setCwvStatus,
            cwvResult,
            setCwvResult,
            cwvError,
            setCwvError,
            cwvDevice,
            setCwvDevice,
            cwvType,
            setCwvType,
            url, 
            setUrl
        }}>
            {children}
        </CwvContext.Provider>
    )
}