export const useStatus = () =>{

    let tempCwvStatus
    let tempCwvResult

    const getCwvStatus = (cwvDataTemp) => {
        // console.log('cwv data to compare',cwvDataTemp)
        let clsPoor = false;
        let cls = false;
        let lcp = false;
        let inp = false;
        let fid = false;
        let lcp_data = false;
        let inp_data = false;
        let fid_data = false;
        tempCwvStatus = ''
        tempCwvResult = ''
        lcp_data = cwvDataTemp?.response?.data?.metric['largest_contentful_paint']?.percentiles.p75;
        lcp = cwvDataTemp?.response?.data?.metric['largest_contentful_paint']?.percentiles.p75 < 2500 ? true : false;
        inp_data = cwvDataTemp?.response?.data?.metric['interaction_to_next_paint']?.percentiles.p75;
        inp = cwvDataTemp?.response?.data?.metric['interaction_to_next_paint']?.percentiles.p75 < 200 ? true : false;
        fid_data = cwvDataTemp?.response?.data?.metric['first_input_delay']?.percentiles.p75;
        fid = cwvDataTemp?.response?.data?.metric['first_input_delay']?.percentiles.p75 < 100 ? true : false;
        cls = cwvDataTemp?.response?.data?.metric['cumulative_layout_shift']?.percentiles.p75 < 0.1 ? true : false;
        clsPoor = cwvDataTemp?.response?.data?.metric['cumulative_layout_shift']?.percentiles.p75 > 0.25 ? true : false;
         console.log('inp', inp, lcp, cls)
        if (lcp && inp && cls) {
            tempCwvStatus = 'All three passed'
        } else if (lcp && !inp && !cls) {
            tempCwvStatus = 'INP and CLS failed'
        } else if (cls && !lcp && !inp) {
            tempCwvStatus = 'LCP and INP failed'
        } else if (inp && !lcp && !cls) {
            tempCwvStatus = 'LCP and CLS failed'
        } else if (inp && cls && !lcp) {
            tempCwvStatus = 'Only LCP failed'
        } else if (inp && lcp && !cls) {
            if (!clsPoor) {
                tempCwvStatus = 'Only CLS failed - need improvement'
            } else if (clsPoor) {
                tempCwvStatus = 'Only CLS failed - poor'
            }

        } else if (lcp && cls && !inp) {

            tempCwvStatus = 'Only INP failed'


        } else if (!lcp && !cls && !inp) {
            tempCwvStatus = 'All three failed'
        }

        if (!lcp_data || !inp_data) {
            tempCwvResult = 'CWV assessment: Not applicable.'
        }
        else if (lcp && cls && inp) {
            tempCwvResult = 'CWV assessment: Passed!'

        } else {
            tempCwvResult = 'CWV assessment: Failed!'
        }

        return { tempCwvStatus, tempCwvResult };
    }
    return getCwvStatus
}
