import { useState } from "react";
import axios from 'axios';
import { navigate } from 'gatsby'

interface IsState {
    response?: any,
    loading: Boolean,
    error?: any
}

export const useApplyForJob = () => {
    const [state, setState] = useState<IsState | null>({
        response: null,
        loading: false,
        error: null
    })

    const applyForJob = async (data) => {
        console.log("data", data);

        setState({ response: null, loading: true, error: null })

        const {
            body,
            file
        } = data

        try {
            const task = await fetch('/.netlify/functions/clickup-apply-for-job', {
                method: 'POST',
                body: JSON.stringify(body)
            })
            const taskID = await task.json()

            const formData = new FormData();
            formData.append('file', file);
            formData.append('task_id', taskID?.id)

            for (let pair of formData.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }
            
            const fileUpload = await axios.post(
                '/.netlify/functions/clickup-busboy',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            )

            setState({ response: fileUpload, loading: false, error: null })
            navigate('/thank-you/')

        } catch (error) {
            setState({
                response: null,
                loading: false,
                error: error
            })
        }
    }

    return [applyForJob, state]

}