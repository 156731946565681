import React, { useEffect, useLayoutEffect } from 'react'

import { navigate } from 'gatsby'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import { Helmet } from 'react-helmet'
import '../../styles/main.scss'

import { useLocation } from "@reach/router"

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'


const Layout = ({
    children
}) => {

    const { pathname, hash } = useLocation()

    function animateFrom(elem, direction) {
        direction = direction || 1;
        var x = 0,
            y = direction * 100;
        if (elem.classList.contains("left") || elem.classList.contains("reveal-left")) {
            x = -100;
            y = 0;
        } else if (elem.classList.contains("right") || elem.classList.contains("reveal-right")) {
            x = 100;
            y = 0;
        }
        elem.style.transform = "translate(" + x + "px, " + y + "px)";
        elem.style.opacity = "0";
        gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
            duration: 2.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            lazy: false
            // overwrite: "auto"
        });
    }

    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }


    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.refresh(true) // to assure refresh on page switch
        gsap.utils.toArray(".reveal").forEach(function (elem) {
            // hide(elem); // assure that the element is hidden when scrolled into view

            ScrollTrigger.create({
                trigger: elem,
                onEnter: function () { animateFrom(elem) },
                onEnterBack: function () { animateFrom(elem, -1) },
                onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
            });
        });

        return () => {
            ScrollTrigger.killAll()
        }
    }, [pathname])

    useEffect(() => {
        if (hash.includes('contact-us')) {
            navigate('/contact-us')
        }
    }, [pathname])

    return (
        <div>
            <Header />
            <main style={{minHeight: '100vh'}}>
                {children}
            </main>
            <Footer />
        </div>
    )
}

export default Layout