// extracted by mini-css-extract-plugin
export var address = "footer-module--address--ba3c5";
export var belowText = "footer-module--below-text--50ef6";
export var container = "footer-module--container--f3aab";
export var containerOne = "footer-module--container-one--e64b6";
export var copyRightText = "footer-module--copy-right-text--98aad";
export var footerContainer = "footer-module--footer-container--6a882";
export var headerLogo = "footer-module--header-logo--a8ea9";
export var instagram = "footer-module--instagram--019d5";
export var log = "footer-module--log--3c0e3";
export var mainContainer = "footer-module--main-container--0b87f";
export var navigation = "footer-module--navigation--1c4c8";
export var privacyNotice = "footer-module--privacy-notice--fc37f";
export var social = "footer-module--social--abfaa";
export var socialIcons = "footer-module--social-icons--4a9c0";