import React, { useState } from 'react'
import * as styles from './link-group.module.scss'
import { Link } from 'gatsby'
import { useLink } from '../../hooks'
import Dropdown from '../../static/header-arrow-dropdown.svg'

type Props = {
    item: {
        title: string,
        sections: NavProps[]
    },
    homeLocation: boolean,
    scrollTo: (id: string) => void
}

type NavProps = {
    title: string
    target?: {
        slug: string,
        title: string
    }
    slug?: string
}

const LinkGroup = ({ item, homeLocation, scrollTo }: Props) => {
    const [show, setShow] = useState(false)
    const { title, sections } = item

    return (
        <li className={styles.container} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            <p className={styles.title}>{title}</p>
            <Dropdown className={styles.close} />
            <div className={styles.subNavGroup}>
                <ul className={styles.subNav}>
                    {sections.map((nav, id) => {
                        const linkSlug = nav.target?.slug || nav.slug

                        return (
                            linkSlug && linkSlug.includes('#') && homeLocation
                                ? <li key={id} className={styles.subNavLink} onClick={() => scrollTo(linkSlug.replace('#', ''))}>
                                    <a href={`#${linkSlug.replace('#', '')}`} className={styles.link}>{nav.title}</a>
                                </li>
                                : <li key={id} className={styles.subNavLink}>
                                    <Link className={styles.link} to={`/${useLink(linkSlug)}`}>{nav.title}</Link>
                                </li>
                        )
                    })}
                </ul>
            </div>
        </li>
    )
}

export default LinkGroup
