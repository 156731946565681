import React, { useState } from "react"

export interface IsState {
    response?: any,
    loading: Boolean,
    error?: any
}

export const useGetCounts = () => {
    const [state, setState] = useState<IsState | null>({
        response: null,
        loading: false,
        error: null
    })

    const getCount = async () => {
        const output = await fetch('/.netlify/functions/readcount-contentful', {
            method: 'GET'
        })
        const res = await output.json()
        return res
    }

    return getCount
}