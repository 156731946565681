import React from "react"
import { useSiteQuery, useLink } from "../../hooks"

export const useSchema = (
    category,
    data
) => {
    const siteUrl = "https://digitup.com/"

    const { headerLogo } = useSiteQuery()

    const getValidThroughDate = () => {
        const today = new Date();
        const validThroughDate = new Date(today.setDate(today.getDate() + 20));
        const year = validThroughDate.getFullYear();
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const month = monthNames[validThroughDate.getMonth()];
        const day = String(validThroughDate.getDate()).padStart(2, '0');
        return `${day} ${month} ${year}`;
    };

    switch (category) {
        case 'website':
            return {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "name": "Digitup",
                "url": "https://digitup.com/",
            }
        case "organization":
            return {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://digitup.com/",
                "logo": headerLogo.file.url.includes('https://') ? headerLogo.file.url : `https:${headerLogo.file.url}`,
                "slogan": 'Power to Evolve',
            }
        case "carousel":
            return {
                "@context": "https://schema.org",
                "@type": "ItemList",
                "itemListElement": data.array.map(({ slug, thumbnailTitle }, index) => {
                    return {
                        "@type": "ListItem",
                        "position": index + 1,
                        "url": `${siteUrl}${useLink(slug)}`,
                        "name": thumbnailTitle
                    }
                })
            }
        case "blog":
            return {
                "@context": "https://schema.org",
                "@type": "Article",
                "headline": data.title,
                "datePublished": data.datePublished,
                "dateModified": data.dateModified,
                "image": [
                    `https:${data.image}`
                ],
                "author":
                    [
                        {
                            "@type": "Organization",
                            "name": "Digitup",
                            "url": siteUrl
                        }
                    ]
            }
        case "jobProfile":
            return {
                "@context": "https://schema.org/",
                "@type": "JobPosting",
                "title": data.jobProfileTitle,
                "description": data.seoJobDescriptionSchema,
                "hiringOrganization": "Digitup Solutions Pvt. Ltd.",
                "datePosted": data.seoJobPostedDateSchema,
                "employmentType": "Full-Time",
                "validThrough": getValidThroughDate(),
                "jobLocation": {
                    "@type": "Place",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "Unit 0001, 13th Floor, Imperia Mindspace, opposite Aipl Business Club",
                        "addressLocality": "Gurugram",
                        "addressRegion": "Haryana",
                        "postalCode": "122005",
                        "addressCountry": "IN"
                    }
                }

            }
    }
}
