import React from "react"
import * as styles from "./richtextServiceCard.module.scss"

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
// import { useLocalePath } from '../../hooks/layout/useLocalePath'
import { useLink } from "../../../../hooks"
import { RichText } from "../../RichText"

const RichTextServiceCard = ({ data, cardHeight }) => {
    const {
        type,
        title,
        slug,
        thumbnailImage,
        content,
        readMoreLink,
        textAlignment,
        imageOrder,
        backgroundColor,
        backgroundColorTop,
        backgroundColorBottom,
    } = data

    return (
        <div
            className={styles.cardContainer}
            style={{
                background:
                    "linear-gradient(180deg, " +
                    backgroundColorTop +
                    " 0%, " +
                    backgroundColorBottom +
                    " 100%)",
            }}
        >
            {(textAlignment === "center" && (
                <Link to={`/${useLink(slug)}`} className={styles.centerAlignedContainer}>
                    <div
                        className={styles.cardContent}
                        style={{ textAlign: textAlignment }}
                    >
                        <div
                            className={`${'heading-4'} ${styles.cardTitle}`}
                            style={{ minHeight: `${cardHeight === 'auto' ? cardHeight : `${cardHeight}px`}` }}
                        >{title}</div>
                        <div className={styles.emptyDiv}></div>
                        <GatsbyImage
                            style={{ order: imageOrder }}
                            image={thumbnailImage.gatsbyImageData}
                            alt={title || thumbnailImage.title}
                        />

                        {content && <RichText content={content} />}
                        <Link to={`${readMoreLink ? (`/${useLink(readMoreLink.target?.slug || readMoreLink?.slug)}`) : slug ? `/${useLink(slug)}` : null}`}>
                            {readMoreLink?.title}
                        </Link>
                    </div>
                </Link>
            )) ||
                (textAlignment === "left" && (
                    <div
                        className={styles.cardContent}
                        style={{ textAlign: textAlignment }}
                    >
                        <div className='heading-5'>{title}</div>
                        <GatsbyImage
                            style={{ order: imageOrder }}
                            image={thumbnailImage.gatsbyImageData}
                            alt={title || thumbnailImage.title}
                        />
                        {content && <RichText content={content} />}
                        <Link to={`${readMoreLink ? (`/${useLink(readMoreLink.target?.slug || readMoreLink?.slug)}`) : slug ? `/${useLink(slug)}` : null}`}>
                            {readMoreLink?.title}
                        </Link>
                    </div>
                ))}
        </div>
    )
}

export default RichTextServiceCard
